import { CleanedRTF } from "@bond-london/graphcms-rich-text";
import classNames from "classnames";
import React from "react";
import { Dots } from ".";
import { RTF } from "../elements";

export const InfographicIntroduction: React.FC<{
  introduction?: CleanedRTF;
  details?: CleanedRTF;
}> = ({ introduction, details }) => {
  if (!introduction && !details) {
    return null;
  }

  return (
    <section
      data-component="Infographic Introduction"
      className={classNames(
        "relative py-section-large max-width z-10",
        "page-grid grid-cols-6 md:grid-cols-12 gap-y-40px",
        "bg-gradient-to-b from-light-blue-0 via-light-blue to-light-blue-0"
      )}
    >
      {introduction && (
        <RTF
          content={introduction}
          classNameOverrides={{ p: "p2" }}
          className={classNames(
            "col-start-2 col-span-12 ga-arrow-space",
            "md:col-start-3 md:col-span-8",
            "lg:col-start-2 lg:col-span-5"
          )}
        />
      )}
      {details && (
        <RTF
          content={details}
          classNameOverrides={{
            p: "p3 ga-arrow-space",
            h2: "ga-arrow-space",
            h3: "ga-arrow-space",
          }}
          className={classNames(
            "col-start-2 col-span-12",
            "md:col-start-3 md:col-span-8",
            "lg:col-start-7 lg:col-span-5"
          )}
        />
      )}
      <Dots
        type="White"
        className={classNames(
          "hidden",
          "lg:block lg:col-start-12 lg:col-span-3"
        )}
      />
    </section>
  );
};
